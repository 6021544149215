
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.advantages {
    position: relative;
    color: #fff;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 100vw;
        transform: translate(-50%);

        height: 100%;
        background-color: $steelbuildingestimates-blue;
        z-index: -1;
    }
}
